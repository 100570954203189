// 
// _table.scss
// 

.table {
  th {
      font-weight: $fw-medium;
  }
}

//Table centered
.table-centered {
  td,th {
      vertical-align: middle !important;
  }
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}


table td#MOTA17510 {background-color: #f4f4fb;}
table td#MOTA17514 {background-color: #d4d4f96e;}
table td#MOTA17515 {background-color:#b4b4e9;}

table td#cool {background-color: #9fd9ff;}
table td#normal {background-color: #f9f7d4;}
table td#warm {background-color:#fbad70;}
table td#hot {background-color:#fb7470b3;}
table td#burnt {background-color:#44000069;}

table td#ok {background-color: #9fffb2;}
table td#limit {background-color: #deffb6;}
table td#overlimit {background-color:#fbee70b3;}
table td#toomuch {background-color:#fb70708f;}

table td#perfect {background-color: #82fd9b;}
table td#good {background-color: #c8ffb6}
table td#average {background-color:#effa8bb3;}
table td#limiit {background-color:#face8bb3;}
table td#tootoomuch {background-color:#fb70708f;}